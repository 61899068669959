




































































.icons-deadline {
  display: block;

  font-size: 18px;
}
