



















































































































































































.group_form {
  display: flex;
  & > .dialogue_comm {
    display: block;
    width: 100%;
  }
}
